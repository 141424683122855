import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ApiResponseModel } from './api.model';
import { User } from '../../../auth/data-access/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private http = inject(HttpClient);
  private apiUrl = environment.apiUrl;

  public postRegisterInvestor(request: RegisterRequest) {
    return this.http.post<ApiResponseModel<any>>(
      this.apiUrl + routes.register(),
      request,
    );
  }

  public postLogin(request: LoginRequest) {
    return this.http.post<ApiResponseModel<LoginResponse>>(
      this.apiUrl + routes.login(),
      request,
    );
  }

  public getUserInfo() {
    return this.http.get<ApiResponseModel<User>>(
      this.apiUrl + routes.userInfo(),
    );
  }
}

const routes = {
  login: () => `/api/v1/auth/two-factor-auth/token`,
  register: () => `/api/v1/user/register-investor`,
  userInfo: () => '/api/v1/auth/me',
};

export interface RegisterRequest {
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}

export interface LoginRequest {
  login: string;
  password: string;
  rememberMe: true;
  twoFactorCode?: string;
  rememberDeviceToken?: string;
}

export interface LoginResponse {
  token: string;
  rememberDeviceToken: string;
  requiresTwoFactorAuth: boolean;
}
